import "./Erro404.css"


const Erro404 = () => {
  return (
    <div className="erro404-main">
        <h1>Erro 404</h1>
    </div>
  )
}

export default Erro404