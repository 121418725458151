import "./Metahumans.css"
import TitleMetahumans from "../../assets/metahumans/title-metahumans.png"
import Slider from "react-slick"

import kaleoImage from "../../assets/metahumans/profile-bio-site_kaleo-new.png"
import tamImage from "../../assets/metahumans/profile-bio-site-tam-new.png"
import villelaImage from "../../assets/metahumans/profile-bio-site_chuck-lee-new.png"
import anaImage from "../../assets/metahumans/profile-bio-site-ana-new.png"
import henriqueImage from "../../assets/metahumans/profile-bio-site-henrique-new.png"
import walterImage from "../../assets/metahumans/profile-bio-site-walter-new.png"


const metahumanos = [
    {
      img: kaleoImage,
      name: "Kaleo Pinheiro",
      jobtitle: "ceo",
      desc: "Com formação em Publicidade e Propaganda, Bacharel em Marketing e pós graduado em e-commerce e gestão financeira, já atuou à frente de projetos como Dona Bonica (Primeiro clube de compra interativo voltado à mulher); DWMV e Sli APP. Especialista em Mobile Ads, com uma visão sempre à frente de seu tempo e aficionado por tecnologia mantém esse amor pela inovação e soluções sociais.",
    },
    {
      img: villelaImage,
      name: "Chuck Lee 'Villela'",
      jobtitle: "cpo",
      desc: "Profissional de Criação, Produto e Inovação, é Designer Digital por formação e tem 20 anos de mercado. É especialista em Projetos e Produtos Phygitais, construiu cases para grandes marcas,  plataformas de mídia e apps que mesclam o físico e digital de maneira orgânica e inovadora. Passou pela Grey, Eletromidia, Ideafixa, ZigPay e TemLance.",
    },
    {
      img: anaImage,
      name: "Ana Camp",
      jobtitle: "Graphic Designer",
      desc: "Formada no Ensino Médio Técnico em Publicidade e estudante de Design Gráfico. Com experiência em Social Media e Branding, busca sempre entregar trabalhos criativos, modernos e exclusivos para os clientes.",
    },
    {
      img: henriqueImage,
      name: "Henrique Colaço",
      jobtitle: "Front End Developer",
      desc: "Programador Front End especialista em websites, webapps e usabilidade em produtos digitais. Desde 2012 atuando em projetos para grandes marcas, desenvolve sites e aplicações web utilizando as mais recentes tecnologias do mercado.",
    },
    {
      img: walterImage,
      name: "Waltin",
      jobtitle: "Graphic Designer",
      desc: "Formado no curso de bacharelado em design gráfico e digital no IED São Paulo. Com experiência em Editorial e Branding, trazendo seu olhar criativo para a empresa.",
    },
]

const Metahumans = () => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      };

  return (
    <section className="metahumans-main" id="team">
        <img src={TitleMetahumans} alt="Metahumanos" className="meta-title"/>
        <div className="list-holder">
            <div className="metahumans-list">
                <Slider {...settings}>
                    {metahumanos.map((item, index) => (
                        <div className="metahumans-item" key={index}>
                            <img src={item.img} alt=""/>
                            <div className="metahumans-item-name">
                                <p>{item.name}</p>
                                <span>{item.jobtitle}</span>
                            </div>
                            <p className="item-desc">{item.desc}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    </section>
  )
}

export default Metahumans