import "./Blog.css"
import {useState, useEffect} from "react"
import { Link } from "react-router-dom"


const Blog = () => {

    const token = "IGQVJXTXpZAb2MtUGJrYlNiUnpCSW1wSGRMbUFRWWJYUE4yVmYzbGh4eU1mVHhQYnpYbTIyNFBrNTV6VS12dk1MaWs0dUNiNW16djhZAbHFyZA1JEcXlOSDVYeGdMcTlGemE4Y2FfMnlyeElHNzVxSEx5NAZDZD"
    const url = "https://graph.instagram.com/me/media?access_token="+token+"&fields=media_url,media_type,caption,permalink,username,timestamp"
    const [instaposts, setInstaposts] = useState(null)

    useEffect(() => {

        async function fetchData(){
        
        const res = await fetch(url)
        const data = await res.json()
        
        setInstaposts(data.data)
        }

        fetchData()
        console.log(instaposts)
        
    },[])

    // Formata a data para padrão brasileiro
    const formatData = (data) => {
        const onlyDate = data.split("T")
        const brDateFormat = onlyDate[0].split("-")

        return brDateFormat[2]+"-"+brDateFormat[1]+"-"+brDateFormat[0]
    }

    return (
        <div className="blog-main">
            <div className="page-title">
                <div className="page-title-content">
                    <h2>[ <span>BLOG</span> ]</h2>
                </div>
            </div>
            <section className="blog-posts">
                <div className="blog-posts-content">
                    {!instaposts &&
                        <span>Loading...</span>
                    }
                    {instaposts &&
                        <ul>
                            {instaposts.map((post, index)=>(
                                <Link to={`/blog/${index}`}>
                                    <li>
                                        <img src={post.media_url} alt={post.username} />
                                        <div className="post-details">
                                            <span className="date">{formatData(post.timestamp)}</span>
                                            <p className="post-title">
                                                {post.caption.split("\n")[0]}
                                            </p>
                                            <p className="post-abr-content">
                                                {post.caption.substring(0,200)}
                                            </p>
                                        </div>   
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    }
                </div>
            </section>
        </div>
    )
}

export default Blog