import "./Posts.css"
import {useState, useEffect} from "react"

const Posts = () => {

    const token = "IGQVJXTXpZAb2MtUGJrYlNiUnpCSW1wSGRMbUFRWWJYUE4yVmYzbGh4eU1mVHhQYnpYbTIyNFBrNTV6VS12dk1MaWs0dUNiNW16djhZAbHFyZA1JEcXlOSDVYeGdMcTlGemE4Y2FfMnlyeElHNzVxSEx5NAZDZD"
    const url = "https://graph.instagram.com/me/media?access_token="+token+"&fields=media_url,media_type,caption,permalink,username,timestamp"
    const [instaposts, setInstaposts] = useState(null)

    useEffect(() => {

        async function fetchData(){
        
        const res = await fetch(url)
        const data = await res.json()
        
        setInstaposts(data)
        }

        fetchData()
        
    },[])

    // Formata a data para padrão brasileiro
    const formatData = (data) => {
        const onlyDate = data.split("T")
        const brDateFormat = onlyDate[0].split("-")

        return brDateFormat[2]+"-"+brDateFormat[1]+"-"+brDateFormat[0]
    }
        
    return (
        <section className="posts-main">
            <div className="title-holder">
                <h2 title="[ BLOG ]">[ <span>BLOG</span> ]</h2>
            </div>
            <div className="posts-holder">
                <ul>
                    {instaposts && 
                    
                    instaposts.data.map((post) => (
                        <li key={post.id}>
                            <img src={post.media_url} />
                            <div className="post-header">
                                <span className="post-user">{post.username}</span>
                                <span className="post-date">{formatData(post.timestamp)}</span>
                            </div>
                            <span className="caption">{post.caption}</span>
                            <a href={post.permalink} target="_blank">Ver o post</a>
                        </li>
                    ))

                    }
                </ul>
            </div>
        </section>
    )
}

export default Posts