import "./GroupKhouse.css"

// Images
import mono4 from "../../assets/groupkhouse/mono4.png"
import lewin from "../../assets/groupkhouse/lewin.png"
import khouse from "../../assets/footer/logo-footer.png"

const GroupKhouse = () => {
    return (
        <section className="groupkhouse-main">
            <div className="groupkhouse-title">
                <h2>[ <span>Grupo Khouse</span> ]</h2>
                <ul>
                    <li>
                        <img src={mono4} alt="Mono4" />
                    </li>
                    <li>
                        <img src={khouse} alt="Lewin" />
                    </li>
                    <li>
                        <img src={lewin} alt="Khouse" />
                    </li>
                </ul>
                {/* <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum dignissim elit et facilisis. Curabitur facilisis nunc eu magna sollicitudin, sed ornare nisi consequat. Nunc rhoncus mi justo, ullamcorper eleifend nibh venenatis vitae. Praesent ligula quam, consectetur id volutpat eget.
                </span> */}
            </div>
            {/* <ul className="group-cases">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul> */}
        </section>
    )
}

export default GroupKhouse