// Slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//ReactRouter
import{BrowserRouter, Routes, Route} from "react-router-dom"

//Pages
import Success from  "./pages/success/Success"
import Main from "./pages/main/Main";
import Erro404 from "./pages/error/Erro404";
import Posts from "./pages/posts/Posts";
import Blog from "./pages/blog/Blog";
import BlogPost from "./pages/blogPost/BlogPost";

//Components
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="*" element={<Erro404 />} />
          <Route path="/sucesso" element={<Success />}/>
          <Route path="/posts" element={<Posts />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog/:blogId" element={<BlogPost />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
