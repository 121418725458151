import "./Cases.css"
import { useEffect } from "react"
import Aos from "aos"
import "aos/dist/aos.css"

import caseEm from "../../assets/cases/case-em.png"
import casePog from "../../assets/cases/case-pog.png"
import caseRiplay from "../../assets/cases/case-riplay.png"
import caseTemLance from "../../assets/cases/case-temlance.png"
import caseZ from "../../assets/cases/case-z.png"

const Cases = () => {

    const casesFadeOffset = 400

    useEffect(() => {
      Aos.init({duration: 1000})
    }, [])

  return (
    <div className="cases-main">
        <div className="cases-title">
            <h2 data-aos="fade-up" data-aos-offset="500">[ <span>Ser Phygital é mudar o mindset</span> ]</h2>
        </div>
        <ul className="cases">
            <li data-aos="fade-up" data-aos-offset={casesFadeOffset}><img src={caseEm} alt="" /></li>
            <li data-aos="fade-up" data-aos-offset={casesFadeOffset}><img src={casePog} alt="" /></li>
            <li data-aos="fade-up" data-aos-offset={casesFadeOffset}><img src={caseRiplay} alt="" /></li>
            <li data-aos="fade-up" data-aos-offset={casesFadeOffset}><img src={caseTemLance} alt="" /></li>
        </ul>
    </div>
  )
}

export default Cases