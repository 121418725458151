import "./Welcome.css"
import Logo from "../../assets/logo-khouse.png"
import {useWindowScroll} from "react-use"
import { useState, useEffect } from "react"
import Typewriter from "typewriter-effect"

const Welcome = () => {

  const {y: pageYOffset} = useWindowScroll();
  const [visible, setVisibility] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {

    if (pageYOffset > 10){
      setVisibility(false)
    }else{
      setVisibility(true)
    }

  },[pageYOffset])

  return (
    <section className="welcome-main" id="home">
        <div className="opacity-mask-full"></div>
        <div className="opacity-mask"></div>
        <div className="welcome-logo-content">
            {/* <img src={Logo} alt="" className="welcome-logo" /> */}
            <h1><span>Be</span> [ <span>
            <Typewriter options={{loop:true,}}
              onInit={(typewriter)=>{
                typewriter
                  .pauseFor(2000)
                  .typeString("khouse")
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString("phygital")
                  .pauseFor(2000)
                  .deleteAll()
                  .start()
              }}
            />  
            </span> ]</h1>
        </div>
        {visible &&
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        }
    </section>
  )
}

export default Welcome