import "./Products.css"
import iconExperience from "../../assets/products/icon-exp-digit.png"
import iconMetaverso from "../../assets/products/icon-metaverso.png"
import iconMarketing from "../../assets/products/icon-marketing.png"
import iconBranding from "../../assets/products/icon-branding.png"

const products = [
  {
    icon: iconExperience,
    title: "experiências phygitais",
    subtitle: "projetos especiais e brand experience phygitais, eventos esportivos, ativações phygitais e marketing sensorial",
  },
  {
    icon: iconMetaverso,
    title: "metaverso",
    subtitle: "Construção de mundos, acessórios e avatares para metaverso; NFT’S",
  },
  {
    icon: iconMarketing,
    title: "marketing digital",
    subtitle: "gestão e apoio comercial, criação de campanhas, conteúdo e peças de comunicação para mídia digital, OOH e social media",
  },
  {
    icon: iconBranding,
    title: "branding",
    subtitle: "Branding e transmedia storytelling",
  },
]

const Products = () => {

  return (
    <section className="products-main" id="products">
        <div className="products-title-holder">
            <h3>[ <span>O que fazemos?</span> ]</h3>
        </div>
        <div className="products-list">
            <ul className="product-list-ul">
              {products.map((item,index)=>(
                <li key={index} className="product-item">
                  <img src={item.icon} alt="" />
                  <h3>{item.title}</h3>
                  <p>{item.subtitle}</p>
                </li>
              ))}
            </ul>
        </div>
    </section>
  )
}

export default Products
 