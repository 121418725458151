import {useState} from "react"
import ScrollTo from "../../helpers/scrollto"
import logoFooter from "../../assets/footer/logo-footer.png"

import "./Header.css"

const Header = () => {

  const [menuVisible, setMenuVisible] = useState(false)


  const handleClick = () => {
    setMenuVisible(!menuVisible)
  }

  const goTo = (event) =>{
    event.preventDefault()

    const hash = event.currentTarget.getAttribute('href');
    const target = document.querySelector(hash);

    setMenuVisible(false)

    if (target) {
      ScrollTo(target, 70);
    }
  }


  return (
    <header className="header-main">
        <a href="#home" onClick={goTo}>
          <img src={logoFooter} alt="" />
        </a>
        <button className="menu-mobile-open-button" onClick={handleClick}></button>
        <div className="menu-holder">
          <ul>
            <li>
              <a href="#about" className="glitch-hover" title="About" onClick={goTo}>About</a>
            </li>
            {/* <li>
              <a href="#vision" className="glitch-hover" title="Vision" onClick={goTo}>Vision</a>
            </li> */}
            <li>
              <a href="#products" className="glitch-hover" title="Products" onClick={goTo}>Products</a> 
            </li>
            <li>
              <a href="#team" className="glitch-hover" title="Team" onClick={goTo}>Team</a>
            </li>
            <li>
              <a href="#contact" className="glitch-hover" title="Contact Us" onClick={goTo}>Contact Us</a>
            </li>
          </ul>
        </div>
        {/* <span className="bekhouse-hashtag">#BeKhouse</span> */}
        {menuVisible && 
          <div className="menu-mobile-main">
            <button className="menu-mobile-close-button" onClick={handleClick}>X</button>
            <ul>
              <li>
                <a href="#about" className="glitch-hover" title="About" onClick={goTo}>About</a>
              </li>
              {/* <li>
                <a href="#vision" className="glitch-hover" title="Vision" onClick={goTo}>Vision</a>
              </li> */}
              <li>
                <a href="#products" className="glitch-hover" title="Products" onClick={goTo}>Products</a>
              </li>
              <li>
                <a href="#team" className="glitch-hover" title="Team" onClick={goTo}>Team</a>
              </li>
              <li>
                <a href="#contact" className="glitch-hover" title="Contact Us" onClick={goTo}>Contact Us</a>
              </li>
            </ul>
          </div>
        }
    </header>
  )
}

export default Header