import "./BlogPost.css"
import { useParams, Link } from "react-router-dom"
import { useState, useEffect } from "react"

const BlogPost = () => {

    const {blogId} = useParams()
    const token = "IGQVJXTXpZAb2MtUGJrYlNiUnpCSW1wSGRMbUFRWWJYUE4yVmYzbGh4eU1mVHhQYnpYbTIyNFBrNTV6VS12dk1MaWs0dUNiNW16djhZAbHFyZA1JEcXlOSDVYeGdMcTlGemE4Y2FfMnlyeElHNzVxSEx5NAZDZD"
    const url = "https://graph.instagram.com/me/media?access_token="+token+"&fields=media_url,media_type,caption,permalink,username,timestamp"
    const [instaposts, setInstaposts] = useState(null)

    useEffect(() => {

        async function fetchData(){
        
        const res = await fetch(url)
        const data = await res.json()
        
        setInstaposts(data.data[blogId])
        }

        fetchData()
        
    },[])

    // Formata a data para padrão brasileiro
    const formatData = (data) => {
        const onlyDate = data.split("T")
        const brDateFormat = onlyDate[0].split("-")

        return brDateFormat[2]+"-"+brDateFormat[1]+"-"+brDateFormat[0]
    }

    return (
        <div className="blogpost-main">
            <div className="blogpost-post">
                {instaposts &&
                <div className="blogpost-post-content">
                    <span className="date">
                        {formatData(instaposts.timestamp)}
                    </span>
                    <p className="post-title">
                        [ <span>{instaposts.caption.split("\n")[0]}</span> ]
                    </p>
                    <p className="post-abr-content">
                        {instaposts.caption}
                    </p>
                </div>
                }
            </div>
        </div>
    )
}

export default BlogPost