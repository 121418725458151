import Header from "../../components/Header/Header"
import Welcome from '../../components/Welcome/Welcome';
import Cases from '../../components/Cases/Cases';
import About from '../../components/About/About';
import Mindset from '../../components/Mindset/Mindset';
import Products from '../../components/Products/Products';
import Metahumans from '../../components/Metahumans/Metahumans';
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import GroupKhouse from "../../components/GroupKhouse/GroupKhouse";

const Main = () => {
  return (
    <div>
        <Header />
        <Welcome />
        <Cases />
        <About />
        {/* <Mindset /> */}
        <Products />
        <Metahumans />
        <Contact />
        <GroupKhouse />
        <Footer />
    </div>
  )
}

export default Main