import {useState, useRef} from "react"
import "./Contact.css"
import emailjs from '@emailjs/browser';

const Contact = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [subj, setSubj] = useState("")
  const [msg, setMsg] = useState("")
  const [loading, setLoading] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true)
    console.log(loading)

    await emailjs.sendForm('service_3otdnc8', 'template_kxf8qto', form.current, 'RX81eSGY8p5bkDZDo')
    .then((result) => {
        console.log(result.text);
        setName("")
        setEmail("")
        setTel("")
        setSubj("")
        setMsg("")
        setEmailSuccess(true)
    }, (error) => {
        console.log(error.text);
    });
    
    setLoading(false)
  };
  
  return (
    <section className="contact-main" id="contact">
      <h3 title="[ FALE COM A KHOUSE ]">[ <span>FALE COM A KHOUSE</span> ]</h3>
      <form ref={form} onSubmit={sendEmail}>
        {emailSuccess &&
          <div className="email-success">
            <h3>Recebemos seu e-mail com sucesso!</h3>
            <span>Entraremos em contato em breve</span>
            <span onClick={()=>setEmailSuccess(false)}>Enviar outro e-mail</span>
          </div>
        }
        {loading &&
          <div className="loading-form">
            <span>Aguarde, estamos recebendo seu email :)</span>
            <span className="loader"></span>
          </div>
        }
        <input type="hidden" name="_captcha" value="false" />
        <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Seu nome" />
        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Seu e-mail"/>
        <input type="tel" name="tel" value={tel} onChange={(e) => setTel(e.target.value)} placeholder="Seu telefone" />
        <input type="text" name="subject" value={subj} onChange={(e) => setSubj(e.target.value)} placeholder="Qual o assunto?"/>
        <textarea name="message" type="text" value={msg} onChange={(e) => setMsg(e.target.value)} placeholder="Agora conte-nos tudo!" />
        {name && email && tel && subj && msg ?
        <button type="submit" value="Enviar">Enviar</button>
        :
        <button type="submit" disabled value="Enviar" className="disabled">Enviar</button>
        }
      </form>
    </section>
  )
}

export default Contact