import SuccessForm from '../../components/SuccessForm/SuccessForm'

const Success = () => {
  return (
    <div>
      <SuccessForm />
    </div>
  )
}

export default Success