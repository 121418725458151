import "./SuccessForm.css"
import {Link} from "react-router-dom"

const SuccessForm = () => {
  return (
    <div className="successform-main">
      <h2 className="glitch" title="_email enviado_">_email enviado_</h2>
      <p>Recebemos o seu e-mail e em breve responderemos</p>
      <Link to="/"><button>Voltar ao site</button></Link>
    </div>
  )
}

export default SuccessForm