import "./About.css"

const About = () => {
  return (
    <section className="about-main" id="about">
        <div className="about-content">
            <h2 className="about-title">[ <span>Be.Khouse</span> ]</h2>
            <h2 className="about-mobile-title">[ <span>Be.<br />Khouse</span> ]</h2>
            <p>
                A <span className="bold">Khouse</span> planeja e desenvolve projetos especiais
                em plataformas <span className="pink">físicas e digitais</span>, de alta conversão
                e geradores e resultados para seus parceiros e clientes
                atuando como <span className="pink">Phygital Experts</span>.
            </p>
            <p>
                Somos facilitadores de agências publicitárias, veículos
                e marcas que queiram atingir seus objetivos no universo
                Phygital e criar conexões verdadeiras com seu público
                dentro deste ecossistema de negócios.
            </p>
        </div>
    </section>
  )
}

export default About