import "./Footer.css"
import logoPink from "../../assets/footer/logo-pink.png"
import ScrollTo from "../../helpers/scrollto"


import logoFooter from "../../assets/footer/logo-footer.png"
import hand from "../../assets/footer/khouse-hand.png"

const Footer = () => {

  const goTo = (event) =>{
    event.preventDefault()

    const hash = event.currentTarget.getAttribute('href');
    const target = document.querySelector(hash);

    if (target) {
      ScrollTo(target, 70);
    }
  }

  return (
    <footer>
      <div className="partners">
        <div className="partners-content">
          <h2>[ <span>Partners</span> ]</h2>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer-content">
          <h2>[ <span>Tem um projeto<br /> em mente?</span> ]</h2>
          <a href="#contact" onClick={goTo}><div><div></div></div>Fale Conosco</a>
          <img src={logoFooter} alt="KhouseCompany" />
          <span>Rua Vergueiro, 3307 - Andar 9 - Sala 3 - (11)94858-3872</span>
        </div>
      </div>
      <img src={hand} className="hand" alt="khousehand" />
    </footer>
  )
}

export default Footer